<template>
  <div class="index">
    <div class="content">
      <div class="about">
        <!-- <div class="about-left">
          <img
            src="../../assets/image/about.png"
            alt=""
          >
        </div> -->
        <div class="about-right">
          <div class="text">
            广东省发改委：鼓励对中小微企业投标人免除投标担保
          </div>
          <div class="text">
            日前，广东省发改委在官网发布《关于规范招投标领域工程建设保证金收取有关工作的
            通知》。根据通知，广东将在工程建设招投标领域，全面推行保函（保险）替代现金缴纳保
            证金，鼓励招标人对中小微企业投标人免除投标担保。
          </div>
          <div class="text">
            在工程建设招投标领域，全面推行保函（保险）替代现金缴纳保证金，投标人、中标人
            以银行保函、保证保险、担保保函提交投标、履约、工程质量保证金的，任何单位不得拒绝
            接收保函（保险）方式的保证金。鼓励使用电子保函，具备使用电子保函条件的，招标人或
            建设单位应在招标文件及合同中明确优先选择使用电子保函。
          </div>
          <div class="text">
            通知明确，严格规范保证金收取，任何单位和个人一律不得以任何形式在工程建设领域
            擅自新设保证金项目。招标人或建设单位要求提交投标保证金、履约保证金、质量保证金的，
            应当在招标文件和合同中载明，明确提交方式、数额（比例）、返还方式、返还时间等信息。
            鼓励招标人对中小微企业投标人免除投标担保。
          </div>
          <div class="text">
            通知要求，进一步加强日常监管和服务保障。各级招投标行政监督部门要加强监管，对
            本地区本领域保函（保险）替代现金保证金及工程价款支付等情况进行跟踪监督。
          </div>
          <div class="text">
            各地市公共资源交易中心（平台运行服务机构）要对进场交易的工程建设项目实行台账
            管理，对每个进场项目的名称、招标人（招标代理机构）、项目投资规模、主管部门，是否
            要求提交投标保证金、履约保证金、工程质量保证金及各类保证金提交方式等情况进行全面
            登记造册，积极配合行政监督部门开展查验工作。
          </div>
          <div class="text">
            在提升系统支撑能力方面，2022年12月底前，各级公共资源交易中心招投标电子交易
            系统要全面上线应用电子保函服务，实现电子保函在线办理、在线提交、在线核验、在线申
            请理赔等服务功能，为全面推行电子保函替代现金保证金等服务创造有利条件。
          </div>
          <!-- <a-button
            type="link"
            @click="$router.push('./brandintroduction')"
          >
            查看更多
          </a-button> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {

    };
  },
  mounted() {
  },
};
</script>
<style lang="less" scoped>
.index{
    display: flex;
    flex-direction: column;
    height: 780px;
    background-color: #f2f2f2;
}
.content {
  width: 1200px;
  height: 700px;
  margin: 0 auto;
  background: #ffff;
  margin-top: 50px;
}
.about {
    display: flex;
    justify-content: center;
    padding-top: 60px;
    .about-left {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
      }
    }
    .about-right {
      position: relative;
      .text {
        text-align:justify;
        margin-left: 100px;
        // width: 670px;
        width: 85%;
        text-indent: 2em;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #666;
        line-height: 36px;
      }
      ::v-deep .ant-btn {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 96px;
        height: 40px;
        border: 1px solid #ddd;
        border-radius: 20px;
        font-size: 0.875rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999;
        background-color: #fff;
        cursor: pointer;
        transition: all 0.5s;
      }
    }
  }
</style>
